<template>
    <div class="quicksearch">
        <form v-on:submit.prevent="doSearch" v-if="filters" class="search-form">
            <div class="search-form__container-wrap">
                <div class="search-form__container">
                    <div class="search-form__type">
                        <ul>
                            <li v-bind:class="{active: !searchRequest.type}">
                                <input id="type-all" type="radio" v-model="searchRequest.type" value=""  @change="doSearch('type')">
                                <label for="type-all"><span><i class="search-form__type--all"></i></span> <b>Alle Fahrzeuge</b></label>
                            </li>
                            <li v-for="type in filters.type" v-bind:key="type.id" v-bind:class="{disabled: type.disabled, active: searchRequest.type == type.id}">
                                <input v-bind:id="'type-' + type.id" v-bind:disabled="type.disabled"  type="radio" v-model="searchRequest.type" v-bind:value="type.id" @change="doSearch('type')">
                                <label v-bind:for="'type-' + type.id"><span><i v-bind:class="'search-form__type--' + type.id"></i></span><b>{{ type.label }}</b></label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="search-form__container-wrap">
                <div class="search-form__container">

                    <div class="search-form__column search-form__column--wide">
                        <div class="search-form__filter" ref="dropdownMenu-1" v-bind:class="{'search-form__filter--disabled': !filtersLoaded, 'search-form__filter--open': selectStatus.producer}">
                            <h3 @click="toggleSelectStatus('producer')">
                                Marke<span v-if="searchRequest.producer != ''">: {{ formatFilterValue(searchRequest.producer, 'producer') }}</span>
                            </h3>
                            <vue-slide-up-down class="search-form__filter-options" :active="selectStatus.producer" :duration="300">
                                <ul>
                                    <li v-bind:class="{active: !searchRequest.producer}">
                                        <input id="producer-all" type="radio" v-model="searchRequest.producer" value=""  @change="toggleSelectStatus('producer');doSearch('producer')">
                                        <label for="producer-all">Beliebig</label>
                                    </li>
                                    <li v-for="producer in filters.producer" v-bind:key="producer.id" v-bind:class="{disabled: producer.disabled, active: searchRequest.producer == producer.id}">
                                        <input v-bind:disabled="producer.disabled" v-bind:id="'producer-' + producer.id" type="radio" v-model="searchRequest.producer" v-bind:value="producer.id" @change="toggleSelectStatus('producer');doSearch('producer')">
                                        <label v-bind:for="'producer-' + producer.id">{{ producer.label }}</label>
                                    </li>
                                </ul>
                            </vue-slide-up-down>
                        </div>

                        <div class="search-form__filter" ref="dropdownMenu-2" v-bind:class="{'search-form__filter--disabled': searchRequest.producer == 0, 'search-form__filter--open': selectStatus.series}">
                            <h3 @click="toggleSelectStatus('series')">
                                Baureihe<span v-if="searchRequest.series != ''">: {{ formatFilterValue(searchRequest.series, 'series') }}</span>
                            </h3>

                            <vue-slide-up-down class="search-form__filter-options" :active="selectStatus.series && searchRequest.producer > 0" :duration="300">
                                <ul>
                                    <li v-bind:class="{active: !searchRequest.series}">
                                        <input id="series-all" type="radio" v-model="searchRequest.series" value=""  @change="toggleSelectStatus('series'); doSearch('series')">
                                        <label for="series-all">Beliebig</label>
                                    </li>
                                    <li v-for="series in filters.series" v-bind:key="series.id" v-bind:class="{disabled: series.disabled, active: searchRequest.series == series.id}">
                                        <input type="radio" v-model="searchRequest.series" v-bind:id="'series-' + series.id" v-bind:disabled="series.disabled"  v-bind:value="series.id" @change="toggleSelectStatus('series'); doSearch('series')">
                                        <label v-bind:for="'series-' + series.id">{{ series.label }}</label>
                                    </li>
                                </ul>
                            </vue-slide-up-down>
                            <div class="search-form__filter-info" v-if="selectStatus.series && searchRequest.producer == 0">
                                Bitte wählen Sie zunächst eine Marke aus
                            </div>
                        </div>

                    </div>

                    <div class="search-form__column search-form__column--wide">
                        <div class="search-form__filter search-form__filter--half search-form__filter--half-first" ref="dropdownMenu-3" v-bind:class="{'search-form__filter--disabled': !filtersLoaded, 'search-form__filter--open': selectStatus.beds}">
                            <h3 @click="toggleSelectStatus('beds')">
                                <span v-if="searchRequest.beds == 0">Schlafplätze</span><span v-if="searchRequest.beds > 0">{{ formatFilterValue(searchRequest.beds, 'beds') }}</span>
                            </h3>
                            <vue-slide-up-down class="search-form__filter-options" :active="selectStatus.beds" :duration="300">
                                <ul>
                                    <li v-bind:class="{active: !searchRequest.beds}">
                                        <input id="beds-all" type="radio" v-model="searchRequest.beds" value="0" @change="toggleSelectStatus('beds'); doSearch('beds')">
                                        <label for="beds-all">Beliebig</label>
                                    </li>
                                    <li v-for="num in bedCache" v-bind:key="num" v-bind:class="{active: searchRequest.beds == num}">
                                        <input v-bind:id="'beds-' + num" type="radio" v-model="searchRequest.beds" v-bind:value="num" @change="toggleSelectStatus('beds'); doSearch('beds')">
                                        <label v-bind:for="'beds-' + num">ab {{ num }} Schlafplätze</label>
                                    </li>
                                </ul>
                                <input type="text" v-model="bedsFrom"  @change="searchRequest.beds = ''"> bis <input type="text" v-model="bedsTo" @change="searchRequest.beds = ''">
                                <p v-show="bedsError">Eingabe unzulässig</p>
                                <button class="button button--yellow" v-on:click.prevent="doSearch('beds-input')"><span>übernehmen</span></button>
                            </vue-slide-up-down>
                        </div>

                        <div class="search-form__filter search-form__filter--half search-form__filter--half-last" ref="dropdownMenu-4" v-bind:class="{'search-form__filter--disabled': !filtersLoaded, 'search-form__filter--open': selectStatus.price}">
                            <h3 @click="toggleSelectStatus('price')">
                                <span v-if="searchRequest.price == 0">Preis</span><span v-if="searchRequest.price > 0">{{ formatFilterValue(searchRequest.price, 'price') }}</span>
                            </h3>
                            <vue-slide-up-down class="search-form__filter-options search-form__filter-options--price" :active="selectStatus.price" :duration="300">
                                <ul v-if="priceCache">
                                    <li v-bind:class="{active: !searchRequest.price}">
                                        <input type="radio" id="price-all" v-model="searchRequest.price" value="0" @change="toggleSelectStatus('price'); doSearch('price')">
                                        <label for="price-all">Beliebig</label>
                                    </li>
                                    <li v-for="num in Math.ceil(priceCache / 20000)" v-bind:key="num" v-bind:class="{active: searchRequest.price == num * 20000}">
                                        <input type="radio" v-bind:id="'price-' + (num * 20000)" v-model="searchRequest.price" v-bind:value="num * 20000" @change="toggleSelectStatus('price'); doSearch('price')">
                                        <label v-bind:for="'price-' + (num * 20000)">bis {{ formatNumber(num * 20000) }}</label>
                                    </li>
                                </ul>
                                <input type="text" v-model="priceFrom" @change="searchRequest.price = ''"> bis <input type="text" v-model="priceTo" @change="searchRequest.price = ''"> &euro;
                                <p v-show="priceError">Eingabe unzulässig</p>
                                <button class="button button--yellow" v-on:click.prevent="doSearch('price-input')"><span>übernehmen</span></button>
                            </vue-slide-up-down>
                        </div>

                        <div class="search-form__submit">
                            <a href="#" v-on:click.prevent="redirectToLiveSearch()" v-if="!isLoading" class="button button--inline button--yellow">
                                <i class="button__icon button__icon--search"></i>
                                <span>{{ count }} Modelle zeigen</span>
                            </a>
                            <p v-if="isLoading"><i class="icon icon--reset icon--rotate"></i> Lade Modelle ...</p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="search-form__container-wrap">
                <div class="search-form__container">

                    <div class="search-form__column search-form__column--price">

                    </div>

                    <div class="search-form__column search-form__column--wide">

                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
    export default {

        components: {
        },

        data() {
            return {
                apiBaseUrl: '',
                isLoading: true,
                bedCache: 0,
                priceCache: 0,
                count: 0,
                page: 0,
                products: [],
                filters: {},
                filtersLoaded: false,
                min_length: 0,
                max_length: 0,
                min_width: 0,
                max_width: 0,
                min_weight: 0,
                max_weight: 0,
                min_price: 0,
                max_price: 0,
                priceFrom: '',
                priceTo: '',
                priceError: false,
                bedsFrom: '',
                bedsTo: '',
                bedsError: false,
                selectStatus: {
                    producer: false,
                    series: false,
                    beds: false,
                    price: false,
                    sorting: false
                },
                searchRequest: {
                    producer: '',
                    series: '',
                    type: '',
                    beds: 0,
                    bedsFrom: '',
                    bedsTo: '',
                    price: 0,
                    priceFrom: '',
                    priceTo: '',
                    sorting: 'modelyear'
                },
                filterActive: false
            }
        },

        created: function() {

            this.apiBaseUrl = '';

            this.getProducts();

            window.addEventListener('resize', this.closeAllFilters);

            document.addEventListener('click', this.checkClick);
        },

        destroyed: function() {
            window.removeEventListener('resize', this.closeAllFilters);
            document.removeEventListener('click', this.checkClick);
        },

        methods: {

            formatNumber(number)
            {
                return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number).replace(',00', '');
            },

            toggleSelectStatus(name)
            {
                let that = this;
                for (let [selectName, value] of Object.entries(that.selectStatus)) {
                    if (value && selectName != name) that.selectStatus[selectName] = false;
                }
                this.selectStatus[name] = !this.selectStatus[name];
            },

            checkClick(e)
            {
                let dropDown = false;
                for(let i = 1; i < 5; i++) {
                    let el = this.$refs['dropdownMenu-' + i];
                    let target = e.target;
                    if ((el == target) || el.contains(target)) {
                        dropDown = true;
                    }
                }
                if (!dropDown) this.closeAllFilters();
            },

            closeAllFilters()
            {
                let that = this;
                for (let [name, value] of Object.entries(that.selectStatus)) {
                    if (value) that.selectStatus[name] = false;
                }
            },


            formatFilterValue(value, filter)
            {
                let displayValue = '';

                if ((filter == 'price') || (filter == 'priceTo')) {
                    displayValue = 'bis ' + this.formatNumber(value);
                } else if (filter == 'priceFrom') {
                    displayValue = 'ab ' + this.formatNumber(value);
                } else if ((filter == 'beds') || (filter == 'bedsFrom')) {
                    displayValue = 'ab ' + value + ' Schlafplätze';
                } else if (filter == 'bedsTo') {
                    displayValue = 'bis ' + value + ' Schlafplätze';
                } else if (filter == 'producer') {
                    if (this.filters.producer) {
                        this.filters.producer.forEach(producer  => {
                            if (producer.id == value) {
                                displayValue = producer.label;
                            }
                        });
                    }
                } else if (filter == 'series') {
                    if (this.filters.series) {
                        this.filters.series.forEach(series  => {
                            if (series.id == value) {
                                displayValue = series.label;
                            }
                        });
                    }
                }

                return (displayValue != '') ? displayValue : value;
            },

            isMultipleChoice(filterName)
            {
                let mutipleChoiceFilter = ['feature', 'typeof', 'bed_type', 'condition'];
                return mutipleChoiceFilter.indexOf(filterName) > -1;
            },

            resetFilter(name)
            {

                if ((name == 'priceFrom') || (name == 'priceTo')) {
                    // Special case price fields
                    this.searchRequest[name] = '';
                } else {
                    if (parseInt(this.searchRequest[name]) != this.searchRequest[name]) {
                        this.searchRequest[name] = '';
                    } else {
                        this.searchRequest[name] = 0;
                    }
                }

                this.doSearch(name);

            },

            resetFilters() {

                this.searchRequest = {
                    producer: '',
                    series: '',
                    type: '',
                    feature: '',
                    beds: 0,
                    bedsFrom: '',
                    bedsTo: '',
                    price: 0,
                    priceFrom: '',
                    priceTo: '',
                    store: 0,
                    condition: '',
                    sorting: 'modelyear'
                };

                this.priceFrom = '';
                this.priceTo = '';

                this.bedsFrom = '';
                this.bedsTo = '';

                this.filterActive = false;
            },

            resetSearch()
            {
                this.resetFilters();

                this.isLoading = true;

                this.products = [];

                this.getProducts();

            },

            redirectToLiveSearch()
            {
                // this.isLoading = true;

                let uri = this.apiBaseUrl + '/fahrzeugsuche#';

                let that = this;

                for (let [key, value] of Object.entries(that.searchRequest)) {
                    if (value) {
                        uri = uri + key + '=' + value + '/';
                    }
                }

                window.location.href = uri;
            },

            doSearch(property)
            {

                let error = false;

                if (property == 'producer') {
                    this.searchRequest.series = 0;
                }

                if (property == 'price') {
                    this.priceFrom = '';
                    this.searchRequest.priceFrom = '';
                    this.priceTo = '';
                    this.searchRequest.priceTo = '';
                }

                if (property == 'price-input') {

                    this.priceFrom = parseInt(this.priceFrom) ? parseInt(this.priceFrom) : 0;
                    this.priceTo = parseInt(this.priceTo) ? parseInt(this.priceTo) : 0;

                    if (this.priceTo > 0) {
                        this.searchRequest.priceTo = this.priceTo;
                    }

                    if (this.priceFrom > 0) {
                        this.searchRequest.priceFrom = this.priceFrom;
                    }

                    if ((this.priceFrom && this.priceTo) && (this.priceFrom > this.priceTo)) {
                        let temp = this.priceFrom;
                        this.priceFrom = this.priceTo;
                        this.priceTo = temp;
                        this.searchRequest.priceFrom = this.priceFrom;
                        this.searchRequest.priceTo = this.priceTo;
                    }
                }

                if (property == 'beds') {
                    this.bedsFrom = '';
                    this.searchRequest.bedsFrom = '';
                    this.bedsTo = '';
                    this.searchRequest.bedsTo = '';
                }


                if (property == 'beds-input') {

                    this.bedsFrom = parseInt(this.bedsFrom) ? parseInt(this.bedsFrom) : 0;
                    this.bedsTo = parseInt(this.bedsTo) ? parseInt(this.bedsTo) : 0;

                    if (this.bedsTo > 0) {
                        this.searchRequest.bedsTo = this.bedsTo;
                    }

                    if (parseInt(this.bedsFrom) > 0) {
                        this.searchRequest.bedsFrom = this.bedsFrom;
                    }

                    if ((this.bedsFrom && this.bedsTo) &&  (this.bedsFrom > this.bedsTo)) {
                        let temp = this.bedsFrom;
                        this.bedsFrom = this.bedsTo;
                        this.bedsTo = temp;
                        this.searchRequest.bedsFrom = this.bedsFrom;
                        this.searchRequest.bedsTo = this.bedsTo;
                    }
                }

                if (property == 'type') {
                    let type = this.searchRequest.type;
                    this.resetFilters();
                    this.searchRequest.type = type;
                }

                if (!error) {
                    this.closeAllFilters();

                    this.isLoading = true;

                    this.page = 0;

                    this.products = [];


                    this.filterActive = this.checkFilterActive();

                    let uri = this.apiBaseUrl + '/?count=1';

                    let that = this;


                    for (let [key, value] of Object.entries(that.searchRequest)) {
                        if (value) {
                            uri = uri + '&filter[' + key + ']=' + value;
                        }
                    }

                    this.axios.get(uri).
                    then((response) => {
                        that.isLoading = false;
                        that.products = response.data.data;
                        that.count = response.data.count;
                        that.filters = response.data.filters;
                    });
                }

            },

            getProducts()
            {
                this.isLoading = true;
                this.page = 0;

                let uri = this.apiBaseUrl + '/?count=1';

                let that = this;

                this.axios.get(uri).
                    then((response) => {
                        that.isLoading = false;
                        that.filtersLoaded = true;
                        that.count = response.data.count;
                        that.products = response.data.data;
                        that.filters = response.data.filters;
                        that.min_price = response.data.filters.min_price;
                        that.max_price = response.data.filters.max_price;

                        if (!that.priceCache) {
                            that.priceCache = that.max_price;
                        }

                        if (!that.bedCache) {
                            that.bedCache = that.filters.max_beds;
                        }

                    });
            },

            checkFilterActive()
            {
                return (this.searchRequest.producer !=  '' ||
                        this.searchRequest.series != '' ||
                        this.searchRequest.type !=  '' ||
                        this.searchRequest.beds !=  0 ||
                        this.searchRequest.bedsFrom !=  '' ||
                        this.searchRequest.bedsTo !=  '' ||
                        this.searchRequest.price !=  0 ||
                        this.searchRequest.priceFrom !=  '' ||
                        this.searchRequest.priceTo !=  '');
            }

        }
    }
</script>
