<template>
  <div id="app">
    <SearchForm />
  </div>
</template>
<script>
import SearchForm from './components/SearchForm.vue'

export default {
  name: 'App',
  components: {
    SearchForm
  }
}
</script>