import Vue from 'vue';
import 'intersection-observer/intersection-observer';
import { ObserveVisibility } from 'vue-observe-visibility';
import VueAxios from 'vue-axios';
import axios from 'axios';
import App from './App.vue';
import VueSlideUpDown from 'vue-slide-up-down'
import VueSmoothScroll from 'v-smooth-scroll'

Vue.use(VueAxios, axios);

Vue.use(VueSmoothScroll, {
    duration: 500,
    offset: -200
    }
);

Vue.config.productionTip = false;

Vue.directive('observe-visibility', ObserveVisibility);

Vue.component('vue-slide-up-down', VueSlideUpDown);

new Vue({
  render: h => h(App),
}).$mount('#app');
